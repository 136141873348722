<template>
  <div class="index">
    <the-base-banner></the-base-banner>
    <div class="Introduction">
      <div class="title">戴森球(北京)科技有限公司简介</div>
      <div class="detail">
        戴森球是将传统大数据结合统计分析，人工智能技术的创新型互联网技术公司。公司的主要产品是依托行业的统计大数据，针对大学，咨询，金融等行业开发的通用型图形化数据解决方案。
      </div>
      <div class="detail">公司还承接了国家部委，医疗行业的多个平台建设项目，涉猎共享单车，新零售等领域。</div>
    </div>
    <!-- 产品服务 -->
    <div class="product">
      <p class="title">大数据统计分析，通用型图形化数据解决方案</p>
      <div class="content">
        <div class="content-menu">
          <div :class="{ 'item-title': true, 'item-title-on': currentProduct === 1 }">
            <img src="@/assets/image/ico_pro01.png" /> 大学研究
          </div>
          <div :class="{ 'item-title': true, 'item-title-on': currentProduct === 2 }">
            <img src="@/assets/image/ico_pro02.png" /> 投资咨询
          </div>
          <div :class="{ 'item-title': true, 'item-title-on': currentProduct === 3 }">
            <img src="@/assets/image/ico_pro03.png" /> 新零售行业
          </div>
          <div :class="{ 'item-title': true, 'item-title-on': currentProduct === 4 }">
            <img src="@/assets/image/ico_pro04.png" /> 个性化定制
          </div>
        </div>
        <div class="content-all" v-show="currentProduct !== 3">
          <div
            @mouseenter="subcurrentProduct = 1"
            @mouseleave="subcurrentProduct = undefined"
            :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 1 ? true : false }"
          >
            <!-- 自研产品分类展示 -->
            <div class="info-img" v-show="currentProduct === 1">
              <img v-show="subcurrentProduct !== 1" class="img-off" src="@/assets/image/ico_product001.png" />
              <img v-show="subcurrentProduct === 1" class="select" src="@/assets/image/ico_product01.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 1">多年数据积累</p>
            <!-- 自研产品分类展示 -->
            <div class="info-img" v-show="currentProduct === 2">
              <img v-show="subcurrentProduct !== 1" class="img-off" src="@/assets/image/ico_product005.png" />
              <img v-show="subcurrentProduct === 1" class="select" src="@/assets/image/ico_product05.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 2">全面行业数据</p>
            <!-- 自研产品分类展示 -->
            <div class="info-img" v-show="currentProduct === 4">
              <img v-show="subcurrentProduct !== 1" class="img-off" src="@/assets/image/ico_product0014.png" />
              <img v-show="subcurrentProduct === 1" class="select" src="@/assets/image/ico_product014.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 4">个性化信息服务</p>
            <div class="info-introduce" v-show="currentProduct === 1">
              数据最早可溯源至1979年，长时间跨度数据为您的观点提供有利佐证。
            </div>
            <div class="info-introduce" v-show="currentProduct === 2">
              涵盖热门行业，直观反映国民经济和社会发展状况，涉及 54 个行业，逾 4.6 亿条数据
            </div>
            <div class="info-introduce" v-show="currentProduct === 4">依照客户的信息需求特点，量身打造信息服务产品</div>
          </div>
          <div
            @mouseenter="subcurrentProduct = 2"
            @mouseleave="subcurrentProduct = undefined"
            :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 2 ? true : false }"
          >
            <div class="info-img" v-show="currentProduct === 1">
              <img v-show="subcurrentProduct !== 2" class="img-off" src="@/assets/image/ico_product002.png" />
              <img v-show="subcurrentProduct === 2" class="select" src="@/assets/image/ico_product02.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 1">智能数据组表</p>
            <!-- 自研产品分类展示 -->
            <div class="info-img" v-show="currentProduct === 2">
              <img v-show="subcurrentProduct !== 2" class="img-off" src="@/assets/image/ico_product006.png" />
              <img v-show="subcurrentProduct === 2" class="select" src="@/assets/image/ico_product06.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 2">数据实时双语更新</p>
            <!-- 自研产品分类展示 -->
            <div class="info-img" v-show="currentProduct === 4">
              <img v-show="subcurrentProduct !== 2" class="img-off" src="@/assets/image/ico_product0015.png" />
              <img v-show="subcurrentProduct === 2" class="select" src="@/assets/image/ico_product015.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 4">专业的信息需求分析</p>
            <div class="info-introduce" v-show="currentProduct === 1">
              系统智能分析上亿条原始数据，依据匹配逻辑，智能组表，免去您反复查询的负担。
            </div>
            <div class="info-introduce" v-show="currentProduct === 2">
              20 年不间断更新，让您对 20 年内数字的研究得心应手。中英文对照，检索方便友善
            </div>
            <div class="info-introduce" v-show="currentProduct === 4">
              产品的构架、功能及内容将全部依据用户的需求进行设计。针对需求进行反复分析论证和试验，进行抓取，不断修正初期的信息定义，以达到完全贴近客户的需求。
            </div>
          </div>
          <div
            @mouseenter="subcurrentProduct = 3"
            @mouseleave="subcurrentProduct = undefined"
            :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 3 ? true : false }"
          >
            <div class="info-img" v-show="currentProduct === 1">
              <img v-show="subcurrentProduct !== 3" class="img-off" src="@/assets/image/ico_product003.png" />
              <img v-show="subcurrentProduct === 3" class="select" src="@/assets/image/ico_product03.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 1">可视化数据图表生成</p>
            <!-- 自研产品分类展示 -->
            <div class="info-img" v-show="currentProduct === 2">
              <img v-show="subcurrentProduct !== 3" class="img-off" src="@/assets/image/ico_product007.png" />
              <img v-show="subcurrentProduct === 3" class="select" src="@/assets/image/ico_product07.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 2">灵活的数据预览与导出功能</p>
            <div class="info-img" v-show="currentProduct === 4">
              <img v-show="subcurrentProduct !== 3" class="img-off" src="@/assets/image/ico_product0016.png" />
              <img v-show="subcurrentProduct === 3" class="select" src="@/assets/image/ico_product016.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 4">丰富的信息来源</p>

            <div class="info-introduce" v-show="currentProduct === 1">
              提供多维的深度广度图表生成，并支持在想可视化数据展示。给您最直观的呈现
            </div>
            <div class="info-introduce" v-show="currentProduct === 2">
              在线预览，灵活导出。快速生成多种格式的可视化报表，数据一览无余。
            </div>
            <div class="info-introduce" v-show="currentProduct === 4">
              提供逾上千种信息源，还可根据客户的实际需求拓展所需的信息渠道
            </div>
          </div>
          <div
            @mouseenter="subcurrentProduct = 4"
            @mouseleave="subcurrentProduct = undefined"
            :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 4 ? true : false }"
          >
            <div class="info-img" v-show="currentProduct === 1">
              <img v-show="subcurrentProduct !== 4" class="img-off" src="@/assets/image/ico_product004.png" />
              <img v-show="subcurrentProduct === 4" class="select" src="@/assets/image/ico_product04.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 1">科学趋势计算</p>
            <div class="info-img" v-show="currentProduct === 2">
              <img v-show="subcurrentProduct !== 4" class="img-off" src="@/assets/image/ico_product008.png" />
              <img v-show="subcurrentProduct === 4" class="select" src="@/assets/image/ico_product08.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 2">专业强大的数据搜索</p>
            <div class="info-img" v-show="currentProduct === 4">
              <img v-show="subcurrentProduct !== 4" class="img-off" src="@/assets/image/ico_product0017.png" />
              <img v-show="subcurrentProduct === 4" class="select" src="@/assets/image/ico_product017.png" />
            </div>
            <p class="info-title" v-show="currentProduct === 4">精准的信息收集</p>
            <div class="info-introduce" v-show="currentProduct === 1">
              结合科学的AI算法，支持直接检索特定趋势数据，直达需求。
            </div>
            <div class="info-introduce" v-show="currentProduct === 2">
              提供快捷和专业检索方式。专业模式下，可以定义关键词组以及词组逻辑关系，精准定位您关心的要素。
            </div>
            <div class="info-introduce" v-show="currentProduct === 4">
              通过系统自动处理，再由人工干预，对信息精准分类，高度集合有效信息，极大降低垃圾信息的干扰
            </div>
          </div>
        </div>

        <div class="content-alone" v-show="currentProduct == 3">
          <p class="alone-title">
            高校财经数据库作为国内首家专业中文财经数据库，推出专为中国高等教育系统定制的信息数据库网站。20
            年之信息积累，回溯期长、数据延续性强，1400 多家平面与网络媒体构成强大数据源，197
            个行业覆盖、海量数据、分类检索、友善的使用界面，500 余家高校、公共图书馆与科研机构正式用户，连续 20
            多年的使用体验与认可。
          </p>
          <div class="alone-item">
            <div
              @mouseenter="subcurrentProduct = 1"
              @mouseleave="subcurrentProduct = undefined"
              :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 1 ? true : false }"
            >
              <div class="info-img">
                <img v-show="subcurrentProduct !== 1" class="img-off" src="@/assets/image/ico_product009.png" />
                <img v-show="subcurrentProduct === 1" class="select" src="@/assets/image/ico_product09.png" />
              </div>
              <span>中国经济新闻库</span> <span>（China Economic News)</span>
            </div>

            <div
              @mouseenter="subcurrentProduct = 2"
              @mouseleave="subcurrentProduct = undefined"
              :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 2 ? true : false }"
            >
              <div class="info-img">
                <img v-show="subcurrentProduct !== 2" class="img-off" src="@/assets/image/ico_product0010.png" />
                <img v-show="subcurrentProduct === 2" class="select" src="@/assets/image/ico_product010.png" />
              </div>
              <span>中国商业报告库</span><span>(China Business Report)</span>
            </div>

            <div
              @mouseenter="subcurrentProduct = 3"
              @mouseleave="subcurrentProduct = undefined"
              :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 3 ? true : false }"
            >
              <div class="info-img">
                <img v-show="subcurrentProduct !== 3" class="img-off" src="@/assets/image/ico_product0011.png" />
                <img v-show="subcurrentProduct === 3" class="select" src="@/assets/image/ico_product011.png" />
              </div>
              <span>中国法律法规库</span><span>(China Laws & Regulations)</span>
            </div>

            <div
              @mouseenter="subcurrentProduct = 4"
              @mouseleave="subcurrentProduct = undefined"
              :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 4 ? true : false }"
            >
              <div class="info-img">
                <img v-show="subcurrentProduct !== 4" class="img-off" src="@/assets/image/ico_product0012.png" />
                <img v-show="subcurrentProduct === 4" class="select" src="@/assets/image/ico_product012.png" />
              </div>
              <span>中国统计数据库</span><span>(China Statistics)</span>
            </div>

            <div
              @mouseenter="subcurrentProduct = 5"
              @mouseleave="subcurrentProduct = undefined"
              :class="{ 'item-info': true, 'item-info-on': subcurrentProduct === 5 ? true : false }"
            >
              <div class="info-img">
                <img v-show="subcurrentProduct !== 5" class="img-off" src="@/assets/image/ico_product0013.png" />
                <img v-show="subcurrentProduct === 5" class="select" src="@/assets/image/ico_product013.png" />
              </div>
              <span>中国上市公司文献库</span><span>(China Listed Company)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheBaseBanner from '@/components/TheBaseBanner'
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  components: {
    TheBaseBanner,
    Carousel3d,
    Slide,
  },
  data() {
    return {
      currentProduct: 1,
      subcurrentProduct: undefined,
      currentPlanmenu: 0,
    }
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../../styles/global.less';
.index {
  cursor: pointer;
}
.Introduction {
  width: 80%;
  margin: 0 auto;
  .title {
    margin: 64px 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }
  .detail {
    text-indent: 40px;
    // width: 80%;
    margin: 0 auto;
    font-size: 20px;
    text-align: left;
    color: #656565;
    line-height: 28px;
  }
}
.product {
  height: 816px;
  overflow: hidden;
  .title {
    margin: 64px 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }
  .content {
    width: 80%;
    margin: 0 auto;
    .content-menu {
      display: flex;
      align-items: center;
      .item-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 56px;
        margin: 0 0 48px;
        border-bottom: 2px solid #e5e8ea;
        font-size: 24px;
        color: #333333;
        text-align: center;
        line-height: 56px;
        img {
          width: 18px;
          height: 20px;
          margin: 0 10px;
        }
      }
      .item-title-on {
        border-bottom: 2px solid #0377ff;
        font-size: 24px;
        color: #0377ff;
      }
    }
    .content-all {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-info {
        width: 22%;
        height: 480px;
        margin: 0 auto;
        border: 2px solid #edf1f5;
        background: #ffffff;
        border-radius: 12px;
        .info-img {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 40px auto 24px;
          .img-off {
            width: 62px;
            height: 62px;
          }
          .select {
            width: 80px;
            height: 80px;
          }
        }
        .info-title {
          margin: 24px 0;
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          color: #333333;
        }
        .info-introduce {
          width: 80%;
          margin: 0 auto;
          font-size: 18px;
          text-align: left;
          color: #656565;
          line-height: 28px;
        }
      }
      .item-info-on {
        box-shadow: 0px 0px 16px 0px #cfd8eb;
      }
    }
    .content-alone {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .alone-title {
        width: 20%;
        padding: 24px 40px;
        margin: 24px 0;
        font-size: 18px;
        text-align: left;
        color: #656565;
        line-height: 40px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 16px 0px #cfd8eb;
      }
      .alone-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 24px 30px;
        text-align: center;

        .item-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 30%;
          height: 220px;
          margin: 0 10px 10px;
          background: #ffffff;
          border-radius: 10px;
          box-shadow: 0px 0px 16px 0px #cfd8eb;
          .info-img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px auto;
            .img-off {
              width: 62px;
              height: 62px;
            }
            .select {
              width: 80px;
              height: 80px;
            }
          }
          span {
            margin: 10px 0;
          }
        }
        .item-info-on {
          box-shadow: 0px 0px 16px 0px #cfd8eb;
        }
      }
    }
  }
}
.customer {
  padding: 0 0 60px;
  background: #0e121a;
  overflow: hidden;
  .title {
    margin: 64px 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }
  .item {
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0.7;
  }
  .item-3d {
    width: 100%;
    height: 100%;
    background: #0e121a;
  }
}
.plan {
  height: 900px;
  overflow: hidden;
  transition: all 2s ease;
  &:hover {
    background-size: 120% 120%;
  }
  .title {
    margin: 64px 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 60px;
    .planmenu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 48px;
      margin: 0 10px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      opacity: 0.9;
      border: 1px solid #ffffff;
      border-radius: 25px;
      img {
        width: 21px;
        height: 21px;
        margin: 0 5px;
      }
    }
    .planmenu-on {
      border: 1px solid #0377ff;
      background: #0377ff;
      opacity: 0.7;
    }
  }
  .item {
    width: 80%;
    margin: 0 auto;
    .item-title {
      margin: 48px 0;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      color: #80baff;
    }
    .item-subtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 32px;
      margin: 16px 0;
      background: #e33d3d;
      font-size: 16px;
      text-align: center;
      line-height: 32px;
      color: #ffffff;
      border-radius: 16px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .item-content {
      margin: 16px 0;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.plan1 {
  background: url(~@/assets/image/back_plan1.png);
  background-size: 100% 100%;
}
.plan2 {
  background: url(~@/assets/image/back_plan2.png);
  background-size: 100% 100%;
}
.plan3 {
  background: url(~@/assets/image/back_plan3.png);
  background-size: 100% 100%;
}
.plan4 {
  background: url(~@/assets/image/back_plan4.png);
  background-size: 100% 100%;
}
.serve {
  min-height: 840px;
  .title {
    margin: 64px 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33%;
      min-height: 660px;
      margin: 0px 30px;
      padding: 10px 30px;
      background: #ffffff;
      border: 2px solid #edf1f5;
      border-radius: 12px;
      &:hover {
        opacity: 1;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 16px 0px #cfd8eb;
      }
      .item-img {
        width: 300px;
        height: 300px;
      }
      .item-til {
        margin: 24px 0;
        font-size: 24px;
        font-weight: 600;
        color: #333333;
      }
      .item-ind {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.8;
        color: #656565;
      }
    }
    .item1 {
      .item-img {
        background: url(~@/assets/image/back_youshi1.png) center no-repeat;
        background-size: 100% 100%;
      }
      &:hover {
        .item-img {
          background: url(~@/assets/image/back_youshi1-on.png) center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .item2 {
      .item-img {
        background: url(~@/assets/image/back_youshi2.png) center no-repeat;
        background-size: 100% 100%;
      }
      &:hover {
        .item-img {
          background: url(~@/assets/image/back_youshi2-on.png) center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .item3 {
      .item-img {
        background: url(~@/assets/image/back_youshi3.png) center no-repeat;
        background-size: 100% 100%;
      }
      &:hover {
        .item-img {
          background: url(~@/assets/image/back_youshi3-on.png) center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
