<template>
  <div class="banner">
    <div class="content">
      <transition-group class="banner-image" name="scroll" tag="div" mode="in-out">
        <div class="image-item item01" v-show="0 === currentImg" :key="0">
          <div class="wrap">
            <div class="item-title">舆情一站式服务</div>
            <p class="item-info">精迅凭借自主研发的数据平台，对海内外全网数据进行监测</p>
            <p class="item-info">并提供实时舆情预警，深度舆情分析等服务</p>
            <!-- <div class="item-btn" @click="goroute('yqjc')">了解更多</div> -->
          </div>
        </div>
        <div class="image-item item02" v-show="1 === currentImg" :key="1">
          <div class="wrap">
            <div class="item-title">行业年鉴统计数据</div>
            <p class="item-info">收录 <span>1949</span> 年至今已出版年鉴，涉及行业广泛，指标丰富</p>
            <p class="item-info">日更新逾 <span>10</span> 万条中英双语数据，拥有 <span>4.64</span> 亿条统计数据</p>
            <!-- <div class="item-btn" @click="goroute('ssw')">了解更多</div> -->
          </div>
        </div>
        <div class="image-item item03" v-show="2 === currentImg" :key="2">
          <div class="wrap">
            <div class="item-title">高校财经数据库</div>
            <p class="item-info">为中国高等院校系统定制的信息化数据库</p>
            <p class="item-info">
              数据全面，含 <span>14</span> 个专业数据库，涉及 <span>19</span> 个大类，<span>197</span> 个行业
            </p>
            <p class="item-info">日更新逾 <span>2000</span> 万字，总量超 <span>100</span> 亿字</p>
            <!-- <div class="item-btn" @click="goroute('gxcj')">了解更多</div> -->
          </div>
        </div>
        <div class="image-item item04" v-show="3 === currentImg" :key="3">
          <div class="wrap">
            <div class="item-title">京津冀协同发展战略研究</div>
            <p class="item-info">
              收录 <span>2014</span> 年起的逾 <span>3</span> 万篇文献，近 <span>8</span> 万张表格，<span>1500</span>
              余万个数据
            </p>
            <p class="item-info">动态资讯、研究报告实时更新</p>
            <!-- <div class="item-btn" @click="goroute('jingjinji')">了解更多</div> -->
          </div>
        </div>
      </transition-group>

      <div class="banner-dots">
        <div :class="{ 'dots-item': true, 'dots-item-on': currentImg === 0 }" @click="manualPlayBanner(0)"></div>
        <div :class="{ 'dots-item': true, 'dots-item-on': currentImg === 1 }" @click="manualPlayBanner(1)"></div>
        <div :class="{ 'dots-item': true, 'dots-item-on': currentImg === 2 }" @click="manualPlayBanner(2)"></div>
        <div :class="{ 'dots-item': true, 'dots-item-on': currentImg === 3 }" @click="manualPlayBanner(3)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentImg: 0,
      timer: null, // 定时器编号
    }
  },
  created() {
    this.autoPlayBanner()
  },
  methods: {
    autoPlayBanner() {
      const imgLength = 2
      this.timer = setInterval(() => {
        if (this.currentImg >= imgLength) {
          this.currentImg = 0
        } else {
          this.currentImg = this.currentImg + 1
        }
      }, 10000)
    },
    manualPlayBanner(index) {
      clearInterval(this.timer)
      this.currentImg = index
      this.autoPlayBanner()
    },
    // 跳转页面
    goroute(name) {
      this.$router.push({ name: name })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../styles/global.less';
.banner {
  width: 100%;
  height: 536px;
  overflow: hidden;
  .content {
    position: relative;
    width: 100%;
    height: 536px;
    .banner-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      // scroll animate
      .scroll-enter-active,
      .scroll-leave-active {
        transition: all 2s ease;
      }
      .scroll-enter {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: translateX(100%);
      }
      .scroll-leave-to {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: translateX(-100%);
      }
      .scroll-enter-to,
      .scroll-leave {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
      }
      .image-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .wrap {
          width: 80%;
          cursor: pointer;
          span {
            font-size: 20px;
            font-weight: 600;
            color: red;
          }
          .item-title {
            font-size: 36px;
            font-weight: 400;
            color: #ffffff;
          }
          .item-info {
            width: 100%;
            margin: 24px 0;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
          }
          .item-btn {
            width: 10%;
            height: 48px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            line-height: 48px;
            color: #ffffff;
            background: #0377ff;
            border-radius: 24px;
          }
        }
      }
      .item01 {
        background: url(~@/assets/image/banner_01.png);
      }
      .item02 {
        background: url(~@/assets/image/banner_02.png);
      }
      .item03 {
        background: url(~@/assets/image/banner_03.png);
      }
      .item04 {
        background: url(~@/assets/image/banner_04.png);
      }
    }
    .banner-dots {
      position: absolute;
      bottom: 5%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .dots-item {
        width: 32px;
        height: 10px;
        margin: 0 10px;
        opacity: 0.4;
        background: #ffffff;
        border-radius: 6px;
      }
      .dots-item-on {
        opacity: 1;
      }
    }
  }
}
</style>
